import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  ButtonDropdown,
  Header,
  Link,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  Container,
  Table,
  Popover,
  KeyValuePairs,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';
import {
  Fragment,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
  useEffect
} from 'react';
import { useToastNotifications } from '../../../hooks/useToastList';
import { CommonDomainProps, ESEC2 } from '../../../models/types';
import { AppContext } from '../../../utils/appContext';
import CopyText from '../../../utils/copy-text';
import {
  EmptyState,
  getFilterCounterText,
  Preferences,
} from '../../../utils/tablePreferences';
import './../../../Styles/table-select.css';
// import { Toast, toastNotifications } from 'ui/notify';
import { SSHBastionDetails } from '../../../Components/Common/SSHBastionDetails';
import { LogRedactionFeedbackWrapper } from '../../../Components/LogRedaction/LogRedactionFeedbackWrapper';
import { ExportCSV } from '../../../utils/exportCSV';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from './../../../utils/constants';
import {
  columns,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  PAGE_SIZE_OPTIONS,
  VISIBLE_CONTENT_OPTIONS,
} from './columns';
import { selectedOptionsType, tableSearchParameters } from './constants';
import { EXECUTE_INSTANCE_COMMAND } from './hooks/useExecuteCommandMutation';
import { LogViewer } from './LogViewer';
import { confirmDialogMapping, getActions, getAdmiralLink } from './utils';
import { ErrorMessageComponent } from "../../../ErrorHandler/errorMessageComponent";
import { ApiList } from "../../../ErrorHandler/utils";
import { isError } from "../../../ErrorHandler/apiErrorHandler";
import { EuiHealth } from '@elastic/eui';

const defaultNodeType = tableSearchParameters[0];

const ShowConfirmModel = (props: any) => (
  <EuiOverlayMask>
    <EuiConfirmModal
      title={props.title}
      onCancel={props.onClose}
      onConfirm={props.onConfirm}
      cancelButtonText={props.cancelText}
      confirmButtonText={props.confirmText}
      buttonColor={props.color}
      defaultFocusedButton="cancel"
    >
      {props.decription}
    </EuiConfirmModal>
  </EuiOverlayMask>
);

interface NodeListProps extends CommonDomainProps {
  ec2Instances: ESEC2[];
  refreshResources: any;
  isLoading: boolean;
  errorMessage: string;
}

const NodeList = (props: NodeListProps) => {
  const [showConfirmModel, setShowConfirmModel] = useState(false);
  const [selectedRow, setSelectedRow] = useState({} as any);
  const [errorMessage, setErrorMessage] = useState(false);
  const [dataNodeType] = useState(defaultNodeType as selectedOptionsType);
  const appContext = useContext(AppContext);
  const [executeCommand, { loading, error }] = useMutation(EXECUTE_INSTANCE_COMMAND);

  const isErrorPresent = isError(error)
  useEffect(() => {
    if (isErrorPresent) {
      setErrorMessage("Unable to complete action. Failed with " + error.message);
    }
  }, [isErrorPresent, error]);

  const toastNotifications = useToastNotifications();
  const handleCancel = useCallback(() => {
    setShowConfirmModel(false);
    setSelectedRow({});
  }, []);

  const handleExecuteCommand = useCallback(async () => {
    try {
      setShowConfirmModel(false);
      confirmDialogMapping[selectedRow.currentAction].executeCommand(
        executeCommand,
        selectedRow,
        props.domainIdentifier,
        toastNotifications
      );
      setSelectedRow({});
    } catch (e) {
      // handle
    }
  }, [selectedRow, executeCommand, props.domainIdentifier, toastNotifications]);

  const handleActionClick = useCallback((currentRow: any) => {
    console.log(currentRow);
    if (currentRow && currentRow.showConfirm) {
      setShowConfirmModel(true);
    }
    setSelectedRow(currentRow);
  }, []);

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(props.isLoading ? [] : props.ec2Instances, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: (
        <EmptyState
          title="No Nodes"
          subtitle="No nodes to display."
          action={<span></span>}
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          subtitle="We can’t find a match."
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  useLayoutEffect(() => {
    collectionProps.ref.current?.scrollToTop();
  }, [dataNodeType, collectionProps.ref]);

  const [openSearchDeploymentTypes, setOpenSearchDeploymentTypes] = useState<Record<string, any>>({});

  const handleOpenSearchDeploymentTypeCheck = useCallback(async (instanceId: any) => {
    setOpenSearchDeploymentTypes(prevTypes => ({
      ...prevTypes,
      [instanceId]: { loading: true }
    }));
    try {
      const response = await executeCommand({
        variables: {
          url: 'aes/domain/dp/node/is-os-containerized',
          args: {
            domain_identifier: props.domainIdentifier,
            instance_id: instanceId,
          }
        }
      });
  
      if (!response.data.executeCommand.success) {
        throw new Error(JSON.stringify(response.data.executeCommand.error));
      }
  
      const responseData = response.data.executeCommand.data.record[instanceId];
  
      setOpenSearchDeploymentTypes(prevTypes => ({
        ...prevTypes,
        [instanceId]: {
          type: responseData.type,
          componentStatus: responseData.componentStatus,
          loading: false
        }
      }));
    } catch (error) {
      console.error('Failed to fetch OpenSearch deployment type:', error);
      
      let errorMessage = 'Failed to fetch deployment type';
      
      try {
        const parsedError = JSON.parse(error.message);
        if (parsedError.extensions && parsedError.extensions.response) {
          const { status, statusText, body } = parsedError.extensions.response;
          errorMessage = `${status} ${statusText}: ${body.exc_type} - ${body.exc_value}`;
        }
      } catch (parseError) {
        console.error('Error parsing error message:', parseError);
      }
  
      setOpenSearchDeploymentTypes(prevTypes => ({
        ...prevTypes,
        [instanceId]: {
          type: 'Unknown',
          componentStatus: {},
          error: errorMessage,
          loading: false
        }
      }));
    }
  }, []);

  return (
      <>
      {props.errorMessage ? (
        <Container header={<Header variant="h2">EC2 instances</Header>}>
        <ErrorMessageComponent errorMessage={props.errorMessage} apiName={ApiList.NODE_LIST}/>
        </Container>
       ) : (
      <Fragment>
      {showConfirmModel ? (
        <ShowConfirmModel
          onClose={handleCancel}
          onConfirm={handleExecuteCommand}
          title={confirmDialogMapping[selectedRow.currentAction].title}
          color={'danger'}
          cancelText={
            confirmDialogMapping[selectedRow.currentAction].cancelText
          }
          confirmText={
            confirmDialogMapping[selectedRow.currentAction].confirmText
          }
        />
      ) : null}
      <Table
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <ExportCSV
                  data={items}
                  filename={'EC2_instances.csv'}
                  loading={props.isLoading}
                />
                <ButtonDropdown
                  items={getActions(handleActionClick, 'logs')}
                  expandableGroups
                  disabled={collectionProps.selectedItems.length === 0}
                  onItemClick={(d) =>
                    getActions(handleActionClick, 'logs')
                      .filter(function (item) {
                        return item.id === d.detail.id;
                      })[0]
                      .onClick(collectionProps.selectedItems[0])
                  }
                >
                  Logs
                </ButtonDropdown>
                <ButtonDropdown
                  items={getActions(handleActionClick, '')}
                  expandableGroups
                  // expandToViewport={true}
                  disabled={collectionProps.selectedItems.length === 0}
                  onItemClick={(d) =>
                    getActions(handleActionClick, '')
                      .filter(function (item) {
                        return item.id === d.detail.id;
                      })[0]
                      .onClick(collectionProps.selectedItems[0])
                  }
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            EC2 instances ({items.length})
          </Header>
        }
        {...collectionProps}
        columnDefinitions={[
          {
            id: 'instanceId',
            header: 'Instance ID',
            sortingField: 'instanceId',
            width: 200,
            cell: (e) => (
              <CopyText
                copyText={e.instanceId}
                copyButtonLabel={
                  <Link
                    externalIconAriaLabel="Opens in a new tab"
                    href={getAdmiralLink(appContext.region.airportCode, e.instanceId)}
                    target="_blank"
                  >
                    {e.instanceId}
                  </Link>
                }
                successText="instanceId copied"
                errorText="instanceId failed to copy"
              />
            ),
          },
          ...columns,
          {
            header: 'OS Deployment Type',
            id: 'osDeploymentType',
            sortingField: 'osDeploymentType',
            cell: (e) => {
              const deploymentInfo = openSearchDeploymentTypes[e.instanceId];
              const capitalizeFirstLetter = (string) => {
                return string.charAt(0).toUpperCase() + string.slice(1);
              };
              return (
                <span>
                  <Button 
                    iconAlign='left'
                    iconName="redo"
                    variant="inline-icon"
                    onClick={() => handleOpenSearchDeploymentTypeCheck(e.instanceId)}
                    ariaLabel="Check OpenSearch deployment type"
                    disabled={deploymentInfo != undefined && deploymentInfo.loading === true}
                  />
                  {deploymentInfo ? (
                    deploymentInfo.loading ? (
                      <StatusIndicator type="loading">Loading</StatusIndicator>
                    ) : deploymentInfo.error ? (
                      <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        content={
                          <StatusIndicator type="error">
                            {deploymentInfo.error}
                          </StatusIndicator>
                        }
                      >
                        {deploymentInfo.type}
                      </Popover>
                    ) : deploymentInfo.type === "container" ? (
                      <Popover
                        fixedWidth
                        header="OpenSearch Container"
                        position="top"
                        content={
                          <KeyValuePairs
                            items={[
                              { label: "Instance", value: <CopyText
                                copyText={e.instanceId}
                                copyButtonLabel={
                                  <Link
                                    externalIconAriaLabel="Opens in a new tab"
                                    href={getAdmiralLink(appContext.region.airportCode, e.instanceId)}
                                    target="_blank"
                                  >
                                    {e.instanceId}
                                  </Link>
                                }
                                successText="Instance Id copied!"
                                errorText="Failed to copy instance id."
                              /> },
                              { label: "Status", value: (
                                <Popover
                                  dismissButton={false}
                                  position="left"
                                  size="small"
                                  header="Container status from SDPDS managed metadata"
                                  content={
                                      <ul>
                                        <li><strong>Active</strong>: Container should be in running state.</li>
                                        <li><strong>Inactive</strong>: Container should be in stopped state.</li>
                                      </ul>
                                  }
                                >
                                  <StatusIndicator type={deploymentInfo.componentStatus.Status === "Active" ? "success" : "error"}>
                                    {deploymentInfo.componentStatus.Status}
                                </StatusIndicator>
                                </Popover>
                              )},
                              { label: "Component", value: <CopyText
                                copyText={deploymentInfo.componentStatus.Component.split("g|")[1]}
                                copyButtonLabel={deploymentInfo.componentStatus.Component.split("g|")[1]}
                                successText="OpenSearch component Copied!"
                                errorText="Failed to copy OpenSearch component"
                              /> }
                            ]}
                          />
                        }
                      >
                        <EuiHealth color={deploymentInfo.componentStatus.Status === "Active" ? "success" : "danger"}>
                          {capitalizeFirstLetter(deploymentInfo.type)}
                        </EuiHealth>
                      </Popover>
                    ) : (
                      capitalizeFirstLetter(deploymentInfo.type)
                    )
                  ) : (
                    "Click to check"
                  )}
                </span>
              );
            },
            width: 200,
          },
          {
            header: 'SSH bastion',
            id: 'bastion',
            sortingField: 'bastion',
            cell: (e) =>
              appContext.stage.toLowerCase() === 'prod' ||
              appContext.region.publicRegion.toLowerCase().includes('eu') ? (
                <LogRedactionFeedbackWrapper
                  e={e}
                  buttonContent="SSH"
                  isTot={false}
                  domainId={props.domainIdentifier}
                  account=""
                />
              ) : (
                <SSHBastionDetails e={e} />
              ),
          },
        ]}
        selectionType="single"
        stickyHeader={true}
        wrapLines={preferences.wraplines}
        resizableColumns
        loading={props.isLoading}
        loadingText={
          props.isLoading ? 'Loading Node list' : 'No Node list found'
        }
        visibleColumns={preferences.visibleContent}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <div className="input-container">
            <PropertyFilter
              className="input-filter"
              i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
              {...propertyFilterProps}
              countText={getFilterCounterText(filteredItemsCount)}
            />
          </div>
        }
        preferences={
          <Preferences
            preferences={preferences}
            setPreferences={setPreferences}
            disabled={false}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            visibleContentOptions={VISIBLE_CONTENT_OPTIONS}
          />
        }
      />

          {selectedRow.currentAction === 'viewLog' ? (
            <LogViewer
              instanceId={selectedRow.instancesId}
              initialLogFile={selectedRow.logFile}
              onClose={() => setSelectedRow({})}
              domainIdentifier={props.domainIdentifier}
            />
          ) : null}
        </Fragment>
      )}
    </>
  );
};

export { NodeList };
